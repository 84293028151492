const Validate = (name, value, isSubmit = false, obj = {}, passwordData) => {
  let data = {};
  let errors = {};
  if (!isSubmit) {
    data = { [name]: value };
  } else {
    data = obj;
  }
  Object.keys(data).forEach((item) => {
    switch (item) {
      case "name":
        if (data[item]?.length > 0) {
          if (!data[item].trim()) {
            errors.name = "Name is Required";
          }
        } else {
          errors.name = "Name is Required";
        }
        break;
      case "subject":
        if (data[item]?.length > 0) {
          if (!data[item].trim()) {
            errors.subject = "Subject is Required";
          }
        } else {
          errors.subject = "Subject is Required";
        }
        break;
      case "description":
        if (data[item]?.length > 0) {
          if (!data[item].trim()) {
            errors.description = "Description is Required";
          }
        } else {
          errors.description = "Description is Required";
        }
        break;
      case "email":
        if (data[item]?.length > 0) {
          if (
            !new RegExp(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ).test(data[item])
          ) {
            errors.email = "Please enter valid Email ID";
          }
        } else {
          errors.email = "Email is Required";
        }
        break;
      case "mobile_number":
        if (data[item]) {
          if (data[item].length < 12) {
            errors.mobile_number = "Please enter valid Mobile Number";
          }
        } else {
          errors.mobile_number = "Number is Required";
        }
        break;
    }
  });
  return errors;
};
export default Validate;
