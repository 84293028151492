import React, { useState } from "react";
import Footer from "../../Components/footer/Footer";
import Header from "../header/Header";
import "./contacts.scss";
import { AiFillCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { ContactUsApi } from "../../Services/ApiServices";
import Validate from "../../Validation";
import { formatPhoneNumber } from "../../utils/helper";

const Contacts = () => {
  const [data, setData] = useState({
    email_attachment: [],
    name: "",
    email: "",
    subject: "",
    description: "",
    mobile_number: "",
  });
  const [images, setImages] = useState([]);
  const [errors, setError] = useState([]);

  const handleInputChange = (e) => {
    const { value, name, type, files } = e.target;
    if (type === "file") {
      if (images.length === 8) {
        toast.warn("you can upload 8 images only");
      } else if (files.length > 0 && images.length < 8) {
        const selectedFIles = [];
        const targetFiles = e.target.files;
        const targetFilesObject = [...targetFiles];
        if (targetFilesObject.length <= 8) {
          targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file));
          });
          setImages([...images, ...selectedFIles]);
          setData({ ...data, [name]: [...data[name], ...targetFiles] });
        } else {
          toast.warn("you can upload 8 images only");
        }
      }
    } else if (name === "mobile_number") {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setData({
        ...data,
        [name]: formattedPhoneNumber,
      });
      let error = Validate(name, formattedPhoneNumber);
      setError(error);
    } else {
      setData({ ...data, [name]: value });
      let error = Validate(name, value);
      setError(error);
    }
  };
  const removeImage = (index) => {
    let remove = images.filter((item, i) => i !== index);
    setImages(remove);
  };

  const submit = () => {
    let error1 = Validate("", "", true, data);
    setError(error1);
    if (Object.keys(error1).length == 0) {
      const formData = new FormData();
      for (let i in data.email_attachment) {
        formData.append("email_attachment[]", data.email_attachment[i]);
      }
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("subject", data.subject);
      formData.append("description", data.description);
      formData.append("mobile_number", data.mobile_number.replace(/\D/g, ""));
      ContactUsApi(formData)
        .then((res) => {
          toast.success(res.message);
          setData({
            email_attachment: [],
            name: "",
            email: "",
            subject: "",
            description: "",
            mobile_number: "",
          });
          setImages([]);
        })
        .catch((err) => {
          toast.error("error is ", err);
        });
    }
  };
  return (
    <div className="main-block contactus-page">
      <div>
        <Header isLandingHeader />
      </div>
      <div className="aboutus-main" style={{ textAlign: "left" }}>
        <div className="container c-container">
          <div className="aboutus-inner">
            <h2>How to Contact Us </h2>
            <p>
              If you have any questions or concerns regarding the Terms of Use
              related to our Site, please feel free to contact us at the
              following email, telephone number or mailing address.{" "}
            </p>
            <p>Email: info@keycma.com</p>
            <p>Telephone Number: 720-226-5328 </p>
            <p>
              Mailing Address: KEY CMA 1328 Switch Grass Drive Castle Rock, CO
              80109
            </p>
            <div className="buisness-form-main">
              <h3>Contact Us Form</h3>
              <div className="row-container d-flex">
                <div className="parts">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    onChange={handleInputChange}
                    value={data.name}
                  />
                  {errors?.name && <p className="errorText">{errors.name}</p>}
                </div>
                <div className="parts">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={data.email}
                    className="form-control"
                    onChange={handleInputChange}
                  />
                  {errors?.email && <p className="errorText">{errors.email}</p>}
                </div>
              </div>
              <div className="row-container d-flex">
                <div className="parts">
                  <label>Mobile Number</label>
                  <input
                    type="phone"
                    name="mobile_number"
                    value={data.mobile_number}
                    minLength={10}
                    maxLength={10}
                    className="form-control"
                    onChange={handleInputChange}
                  />
                  {errors?.mobile_number && (
                    <p className="errorText">{errors.mobile_number}</p>
                  )}
                </div>
                <div className="parts">
                  <label>Subject</label>
                  <input
                    type="text"
                    name="subject"
                    value={data.subject}
                    className="form-control"
                    onChange={handleInputChange}
                  />
                  {errors?.subject && (
                    <p className="errorText">{errors.subject}</p>
                  )}
                </div>
              </div>
              <div className="row-container">
                <label>Description</label>
                <textarea
                  type="text"
                  name="description"
                  value={data.description}
                  className="form-control"
                  onChange={handleInputChange}
                />
                {errors?.description && (
                  <p className="errorText">{errors.description}</p>
                )}
              </div>
              <div className="row-container">
                <label>Attach Files</label>
                <input
                  type="file"
                  multiple
                  name="email_attachment"
                  className="form-control"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleInputChange}
                />
              </div>
              <div className="row-container form-control image-preview temp">
                <div className="AllImgWrap">
                  {images.map((item, index) => {
                    return (
                      <div className="ImgWrap">
                        <img src={item} />
                        <AiFillCloseCircle onClick={() => removeImage(index)} />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="row-container ">
                <button
                  className="btn btn-pink btn-outline-success"
                  onClick={submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contacts;
