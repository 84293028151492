import React from 'react'
import Footer from '../../Components/footer/Footer'
import Header from '../header/Header'

const TermsCondition = () => {
  return (
    <>
      <div className="main-block">
        <div>
          <Header isLandingHeader />
        </div>
        <div className="aboutus-main">
          <div className="container c-container">
            <div className="aboutus-inner">
              <h5>OUR COMMITMENT</h5>
              <p>
                KeyCMA retrieves usage data to enhance a better experience for our subscribers.
                KeyCMA is carefully responsible of distributing the data. Your privacy, security, and
                data is critical and important to us. KeyCMA promises to never sell your information.
              </p>
            </div>
          </div>
          <div className="aboutus-inner-second"  style={{textAlign:"left"}}>
            <div className="container">
              <div className="aboutus-inner">
                <h2 className='text-center'>Terms of Use</h2>

                <h5 className='mt-2'>1. AGREEMENT TO TERMS </h5>

                <p>
                  These Terms of Use constitute an agreement made between you, whether personally or
                  on behalf of an entity (“you) and Silver Key Realty Group concerning your access to
                  and use KeyCMA as well as any other media form, media channel, mobile website or
                  mobile application related, linked, or otherwise connected thereto the KeyCMA site.
                  You agree that by accessing the Site, you have read, understood, and agreed to be
                  bound by all of these Terms of Use. If you do not agree with all of these Terms of Use,
                  then you are expressly prohibited from using the sited you must discontinue use
                  immediately.
                </p>
                <h5 className='mt-2'>2. SUPPLEMENTAL TERMS AND CONDITIONS</h5>
                <p>
                  Information that may be posted on the Site form time to timer hereby expressly
                  incorporated herein by reference. KeyCMA reserves the right, in our sole discretion, to
                  make changes or modifications to these Terms of Use at any time and for any reason.
                  We will alert you about any changes by updating the “Last Updated” date of these
                  Terms of Use, and you waive any right to receive specific notice of each such change.
                  You will be subject to, and will be deemed to have been made aware of and to have
                  accepted, the changes in any revised Terms of Use by your continued use of the Site
                  after the date such revised Terms of Use are posted. Continued use of the site
                  following the posting of any updates or changes to our Terms of Use shall mean that
                  you are in agreement and acceptance of such changes.
                </p>
                <h5 className='mt-2'>3. INTELLECTUAL PROPERTY RIGHTS </h5>
                <p>Other than content you own, which you may have opted to include on this Website
                  (such as logos, images to which you own the rights, and your personal data), under
                  these Terms of Use, KeyCMA and/or its licensors own all rights to the intellectual
                  property and material contained in this Site, and all such rights are reserved. You are
                  granted a limited access only, subject to the restrictions provided in these Terms of
                  Use, for the express purpose of using the KeyCMA Application contained on this Site.
                  Other restrictions as to the use of imported data from other service providers such as
                  multiple listing service providers must be adhered to by abiding by the rules set forth in
                  their company's terms of service AND/OR contract. Any violation of the terms of </p>
                <p>service of service and use of the multiple listing service provider's data can result in the
                  suspension of your use and access to this Site.</p>
                <h5 className='mt-2'>4. PROHIBITED ACTIVITIES </h5>
                <ul>
                  <li>Systematically retrieve data or other content form the Site to create, compile, directly or indirectly, a
                    collection, compilation, database, or directory without written permission from KeyCMA.</li>
                  <li>Trick, defraud, or mislead us and other users, especially any attempt to learn sensitive account
                    information such as user passwords.</li>
                  <li>Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
                  <li>Engage in any automated use of the system, such as using scripts to send comments or messages,
                    or use any data mining, robots, or similar stat gathering and extraction tools.</li>
                  <li>Delete the logo or other proprietary rights notice from any content.</li>
                  <li>Publishing in any comments, blog posts, reviews, or by any other public media, any content deemed
                    by the Site’s management designed to negatively affect the Site’s operation AND/OR business
                    profitability.</li>
                  <li>Attempt to impersonate another user or person or use the username of another user.</li>
                  <li>Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other
                    code.</li>
                  <li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of
                    the software comprising or in any haymaking a part of the Site.</li>
                </ul>

                <h5 className='mt-2'>5. USER REPRESENTATIONS </h5>
                <p>By using the Site, you represent and warrant that; (1) all registration information you
                  submit will be true, accurate, current, and complete; (2) you will maintain the accuracy
                  of such information and promptly update such registration information as necessary;
                  (3) you have the legal capacity and you agree to comply with these Terms of Use; (4)
                  you will not access the Site through automated non-human means, whether through a
                  bot, script, or otherwise; (5) you will not use the site for any illegal or unauthorized
                  purpose; and (6) your use of the Site will not violate any applicable law or regulation.</p>
                <h5 className='mt-2'>6. USER REGISTRATION </h5>
                <p>You will be required to register with the Site. You agree to keep your password
                  confidential and will be responsible for all use of your account and password. We
                  reserve the right to remove, reclaim, or change a username you select if we determine,
                  in our sole discretion, that such username is inappropriate, obscene, or otherwise
                  objectionable.</p>
                <h5 className='mt-2'>7. WARRANTY DISCLAIMER </h5>
                <p>You expressly understand and agree that this Site or this application and the
                  information and Content available through this Site or this application are provided on
                  an “as is” “as available” basis. KeyCMA does not warrant that this Site or this
                  application will be uninterrupted or error-free, and KeyCMA makes no warranty as to
                  the accuracy, completeness, authenticity, or reliability of any information or content
                  available through the Site or this application. KeyCMA expressly disclaims any and all
                  warranties of any kind with respect to the Site or this application and the information or
                  content available through the Site or this application, including but not limited to any
                  implied warranties of expectation of privacy, merchantability or fitness for a particular
                  purpose. This Site or this application and the information available through this Site or
                  this application are always subject to change.</p>
                <p>KeyCMA assembles publicly available property records and program data to arrive at
                  an approximate home value. The evaluation is not defined as an appraisal report
                  prepared by a licensed professional appraiser.</p>
                <p>Nothing on this Site or this application is intended to be, or is to be construed, as legal
                  advice. If you have legal questions please contact your legal counsel.</p>
                <h4 className='mt-2'>5. THIRD-PARTY WEBSITE AND CONTENT</h4>
                <p>The Site may contain (or you may be sent via the Site) links to other web sites (“ThirdParty Websites”) as well as articles, photographs, text, pictures, designs, information,
                  applications, and other content or items belonging to our originating from third parties
                  (“Third-Party content”). Such Third-Party Websites and Third-Party content are not
                  investigated, monitored, or checked for accuracy, or completeness by us, and we are
                  not responsible for any Third-Party Websites accessed through the KeyCMA Site. Any
                  purchases you make through Third-Party Websites will be through other websites and
                  from other other companies, and we take no responsibility whatsoever in relation to
                  such purchases which are exclusively between you and the applicable third party. You
                  agree and acknowledge that we do not endorse the products or services offered on
                  Third-Party Websites and you should hold us harmless from any harm caused by your
                  purchase of such products or services.</p>
                <h4 className='mt-2'>6. USER GENERATED CONTRIBUTIONS </h4>
                <p>KeyCMA may grant you the ability to revise information related to properties displayed
                  on the Site (User Generated Content). Contributions may be viewable by other users of
                  the Site and through third-party websites. As such, any Contributions you transmit
                  may be treated as non-confidential and non-proprietary. By posting your Contributions
                  to any part of the Site, you automatically grant, and you represent and warrant that you
                  have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, nonexclusive, transferable, royalty -free, fully-paid, worldwide right, and license to host,
                  use, copy, reproduce, disclose, broadcast, store, and distribute such Contributions for
                  any purpose. We do not assert any ownership over your contributions. We are not
                  liable for any statements or representations in in your Contributions provided by you in
                  area of the Site. We have no obligation to monitor your Contributions. You are solely
                  responsible for your Contributions to the Site and you expressly agree to exonerate us
                  from any and all responsibility and to refrain from any legal action against us regarding
                  Contributions.</p>
                <h4 className='mt-2'>7. DISCLAIMER</h4>
                <p>THE SITE IS PROVIDED ON AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
                  YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO
                  THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
                  EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
                  INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE. AND NONINFRINGEMENT, WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
                  ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF
                  ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
                  RESPONSIBILITY FOR ANY ERRORE, MISTAKES, OR INACCURACIES OF CONTENT
                  AND MATERIAL.</p>
                <h4 className='mt-2'>8. LIMITATIONS OF LIABILITY/INDEMNIFICATION</h4>
                <p>In no event will we our our directors, employees, or agents be liable to you or any thirdparty for any direct, indirect, consequential, exemplary, incidental, special, or punitive
                  damages, including lost profit, lost revenue, loss of data, or other damages arising from
                  the use of the Site, even if we have been advised of the possibility of such damages.</p>
                <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
                  affiliates, and all of our respective officers, agents, partners, and employees, from and
                  against any loss, damage, liability, claim, or demand made by any third-party due to or
                  arising out of: (1) your Contribution; (2) use of the Site; (3) breach of these Terms of
                  Use; (4) any breach of your representations and warranties set forth in these Terms of
                  Use; (5) your violation the rights of a third-party, including but not limited to intellectual
                  property rights; or (6) any overt harmful act toward any other user of the Site with
                  whom you connected via the Site. Notwithstanding the foregoing, we reserve the right,
                  at your expense, to assume the exclusive defense and control of any matter for which
                  you are required to indemnify us, and you agree to cooperate, at your expense, with
                  our defense of such claims. We will use reasonable efforts to notify you of any such
                  claim, action, or proceeding which is subject to this indemnification upon becoming
                  aware of it.</p>
                <h4 className='mt-2'>9. DISPUTE RESOLUTION </h4>
                <h5>Informal Negotiations </h5>
                <p>To expedite resolution and control the cost of any dispute, controversy, or claim related
                  to these Terms of use (each “Dispute” and collectively, the “Disputes) brought by either
                  you or us (individually, a “Party” and collectively, the “Parties), the Parties agree to first
                  attempt to negotiate any Dispute informally for at least thirty days before initiating
                  arbitration. Such informal negotiations commence upon written notice from one Party
                  to the other Party.</p>
                <h5 className='mt-2'>10. TERM AND TERMINATION </h5>
                <p>KeyCMA Terms of Use shall remain in full force and effect while use of this Site.
                  Without limiting any other provision of these Terms of Use, we reserve the right to, in
                  our sole discretion immediately upon notice, deny access to and use of the site to any
                  person for any reason or no reason, including without limitation for breach of any
                  representation, warranty, or covenant contained in these Terms of Use. We may
                  terminate your use or participation of the Site in the event that you are using the Site in
                  any way other than expressly permitted by these Terms of Use.</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default TermsCondition