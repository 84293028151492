import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import "./PaymentCard.scss";
import logo from "../../../Common/img/logo.png";
import { registerAPI } from "../../../Services/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../Common/styles/main.scss";
import "../../../Pages/login/Login.scss";
import {
  loginState,
  promocodeadd,
  subcriptionPlan,
} from "../../../Redux/actions";
import { priceformater } from "../../../utils/helper";

function PaymentCard() {
  const location = useLocation();
  const state = useSelector((state) => state.Login.subscriptionPlan);
  const state2 = useSelector((state) => state.Login);
  const navigate = useNavigate();
  const [registerdata, setRegisterData] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state) {
      setRegisterData(location.state.data);
    }
  }, [location.state]);

  useEffect(() => {
    if (
      registerdata?.exp_month &&
      registerdata?.exp_year &&
      registerdata?.subscription_plan_id &&
      registerdata?.card_number &&
      registerdata?.stripe_token &&
      registerdata?.plan_amount !== ""
    ) {
      registerAPI(registerdata)
        .then((res) => {
          if (res.status_code === 200) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("subscribed", res.data.is_subscribed);
            if (res.data.is_subscribed === 1) {
              toast.success(res.message);
              // herechanges
              setTimeout(() => {
                dispatch(promocodeadd());
                dispatch(subcriptionPlan());
              }, 4000);
              navigate("/home");
            }
          } else {
            if (res.data && res.data.message) {
              dispatch(loginState("login"));
              for (let i in res.data.data) {
                dispatch(promocodeadd());
                dispatch(subcriptionPlan());
                toast.error(res.data.data[i].join(" "));
                navigate("/login");
              }
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong.");
        });
    }
  }, [registerdata]);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: card,
    });


    if (error) {
      toast.error(error?.response?.data);
    } else {
      stripe.createToken(card).then(({ error, token }) => {
        
        if (error) {
          toast.error("error message ", error.message);
        } else {
          setRegisterData({
            ...registerdata,
            exp_month: paymentMethod?.card?.exp_month,
            exp_year: paymentMethod?.card?.exp_year,
            subscription_plan_id: state?.id,
            card_number: paymentMethod?.card?.last4,
            stripe_token: token.id,
            coupon_id: state2?.promocode ? state2?.promocode?.promocode : "",
            // plan_amount: Object?.keys(state2?.promocode)?.length > 0
            //   ? state2?.promocode?.amount_off
            //   : state2?.subscriptionPlan?.amount,
            // plan_amount: state2?.subscriptionPlan?.amount ? state2?.subscriptionPlan?.amount : state2?.promocode?.amount_off
            plan_amount: state2?.promocode && Object?.keys(state2?.promocode)?.length > 0
            ?  state2?.promocode?.amount_off
            : state2?.subscriptionPlan?.amount
          })
        }
      });
    }
  };

  return (
    <>
      <div className="main-block-login">
        <div className="inner-block">
          <div className="login-block">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <h2>Subscription Details</h2>
            <div className="auth-form">
              <div className="form-block">
                <div className="login-filed">
                  <div className="form-group">
                    <label className="title-tab-login cardDetail">
                      {state?.title} : {priceformater(state?.amount)}
                    </label>
                    <label className="title-tab-login cardTitle">
                      (Enjoy 14 days of free trial, You will be charged on{" "}
                      {state?.date})
                    </label>
                    <CardElement />
                  </div>
                  <button
                    type="submit"
                    className="form-button btn"
                    disabled={!stripe}
                    onClick={handleSubmit}
                  >
                    Confirm Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentCard;
