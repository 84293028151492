import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../../Common/styles/main.scss";
import "../footer/Footer.scss";

//images
import Footerlogo from "../../Common/img/footer-logo.png";
import Ic_facebook from "../../Common/img/Ic_facebook.png";
import Ic_linkedin from "../../Common/img/Ic-linkedin.png";
import Ic_twitter from "../../Common/img/Ic-twitter.png";
import { toast } from "react-toastify";

const Footer = () => {
  const navigate = useNavigate();
  const date = new Date().getFullYear();
  return (
    <Fragment>
      <div className="footer-block">
        <div className="container c-container">
          <div className="row justify-content-md-between">
            <div className="col-md-4">
              <div className="footer-logo">
                <img src={Footerlogo} alt="footer logo" />
                <p>1328 Switch Grass Drive, Castle Rock, CO 80109</p>
                <div className="social-icons">
                  <ul>
                    {/* <li onClick={() => toast.success("Will be added sone")}>
                      <img src={Ic_facebook} alt="facebook logo" />
                    </li> */}
                    <li>
                      <a href="https://www.facebook.com/people/Keycma/61554262774127/">
                        <img src={Ic_facebook} alt="facebook logo" />
                      </a>
                    </li>
                    <li onClick={() => toast.success("Will be added sone")}>
                      <img src={Ic_linkedin} alt="linkedin logo" />
                    </li>
                    <li onClick={() => toast.success("Will be added sone")}>
                      <img src={Ic_twitter} alt="twitter logo" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-7 text-right footer-links">
              <div className="quick-links">
                <h4>Quick Links</h4>
                <ul>
                  <li>
                    <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                      {" "}
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" onClick={() => window.scrollTo(0, 0)}>
                      {" "}
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/subscription-plan"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {" "}
                      Subscription
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="quick-links">
                <h4>Company</h4>
                <ul>
                  <li>
                    <Link
                      to="/terms-condition"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {" "}
                      Terms
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {" "}
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="quick-links">
                <h4>Ready to get Started?</h4>
                <div>
                  <button
                    className="btn btn-pink btn-outline-success"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate("/contact-us");
                    }}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 pt-2 copyright">
              <p>Copyright ©{date} All Rights Reserved </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
