import * as axios from "axios";
import API from "./Constant";
import { toast } from "react-toastify";

// Configure axios instance for API call
const axiosInstance = axios.create({
  baseURL: `${API.backendApi}/`,
});
// Globle API request function
function CreateRequestFunc(methods, url, body, header) {
  let config = {
    method: methods,
    url,
    headers: header
      ? header
      : {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "*",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
    data: body,
  };

  // Axios instance for response
  return axiosInstance(config)
    .then((response) => {
      if (response.data.status !== undefined && response.data.status === 99) {
        toast.error("Unauthorized access");
        localStorage.clear();
        window.location.replace("/login");
      }
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Unauthorized access"
        );
        localStorage.clear();
        return error.response;
      } else {
        return error.response;
      }
    });
}
const withoutToken = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true,
  "Access-Control-Allow-Headers": "*",
  "Access-Control-Allow-Methods": "*",
  "Content-Type": "application/json",
  Accept: "application/json",
};
// Login/Register/Logout APIs
export const loginAPI = async (body) => {
  return await CreateRequestFunc("post", `/api/login`, body, withoutToken);
};
export const verifyDataAPI = async (body) => {
  return await CreateRequestFunc("post", `/api/verify-data`, body);
};
export const registerAPI = async (body) => {
  return await CreateRequestFunc("post", `/api/register`, body);
};
export const logoutAPI = async () => {
  return await CreateRequestFunc("get", `/api/logout`);
};

// promocode api
export const promocodeAPI = async (body) => {
  return await CreateRequestFunc(
    "post",
    `/public/api/stripe/check-coupons`,
    body
  );
};

// Forgot/Reset APIs
export const forgotPasswordAPI = async (body) => {
  return await CreateRequestFunc("post", `/api/forgot-password`, body);
};
export const resetPasswordAPI = async (body) => {
  return await CreateRequestFunc("post", `/api/reset-password`, body);
};

// Upload Images API
export const uploadImagesAPI = async (body) => {
  return await CreateRequestFunc("post", `/api/store-property-images`, body);
};
export const getUploadedImagesAPI = async (uuid, body) => {
  return await CreateRequestFunc("post", `/api/get-images/` + uuid, body);
};
//get Image for PDF Pages
export const getUploadedImagesInBase64API = async (uuid, body) => {
  return await CreateRequestFunc(
    "post",
    `/api/encoded/img/list/${uuid}?`,
    body
  );
};

///faqs API
export const faqsAPI = async () => {
  return await CreateRequestFunc("get", `/api/faq-list`, withoutToken);
};

//pdf generat and send pdf in email
export const sendGeneratePDF = async (body) => {
  return await CreateRequestFunc("post", `/api/send-pdf`, body);
};

//subcription plan
export const subcriptionAPI = async () => {
  return await CreateRequestFunc(
    "get",
    `/api/subscription-plan-list`,
    null,
    withoutToken
  );
};

//Subscription

export const updateSubscription = async (body) => {
  return await CreateRequestFunc(
    "post",
    `/api/change-subscription-plan`,
    body,
    {
      "Access-Control-Allow-Methods": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  );
};

export const cancelSubscription = async (body) => {
  return await CreateRequestFunc("post", `/api/cancel-subscription`, body);
};

export const changeSubscriptionCardDetails = async (body) => {
  return await CreateRequestFunc(
    "post",
    `/api/change-subscription-paymentcard`,
    body
  );
};

export const uploadProfilePic = async (body) => {
  return await CreateRequestFunc("post", `/api/upload-profile-pic`, body);
};

export const userDetails = async () => {
  return await CreateRequestFunc("get", `/api/user-details`);
};

export const storeBusinessDetail = async (body) => {
  return await CreateRequestFunc("post", `/api/store-business-detail`, body);
};

export const adjustmentDefaultValues = async () => {
  return await CreateRequestFunc("get", `/api/property-adjustment-valu-list`);
};
export const editadjustmentDefaultValuesAPI = async (body) => {
  return await CreateRequestFunc(
    "post",
    `/api/store-user-property-adjustment-value`,
    body
  );
};

export const searchAddressRets = async (body) => {
  return await CreateRequestFunc("post", `/api/search-address-rets`, body);
};

export const saveImageRets = async (body) => {
  return await CreateRequestFunc("post", `/api/save-image-rets`, body);
};

// history save api
export const HistoryPostApi = async (body) => {
  return await CreateRequestFunc("post", `/api/post-history`, body);
};

// get All History Api
export const getAllHistory = async (page) => {
  return await CreateRequestFunc("get", `/api/get-history?page=${page}`);
};

//delete uploaded image
export const DeleteUploadedImageApi = async (body, param) => {
  return await CreateRequestFunc(
    "post",
    `/api/get-images/delete/${param}`,
    body
  );
};

//pdf count api
export const updateReportCounterApi = async (body) => {
  return await CreateRequestFunc("post", `/api/update-report-counter`, body);
};

//
export const ContactUsApi = async (body) => {
  return await CreateRequestFunc("post", `/api/contact-us`, body);
};
export const requestDemoApiCall = async (body) => {
  return await CreateRequestFunc("post", `/api/request-demo`, body);
};
