import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "../../Common/styles/main.scss";
import Header from "../header/Header";
import Footer from "../../Components/footer/Footer";
import "../aboutUs/AboutUs.scss";
import Adetailsimg from "../../Common/img/KeyCMA_Client_image.jpg";
import { useDispatch } from "react-redux";
import { loginState } from "../../Redux/actions";

const AboutUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Fragment>
      <div className="main-block">
        <div>
          <Header isLandingHeader />
        </div>
        <div className="aboutus-main">
          <div className="container c-container">
            <div className="aboutus-inner">
              <span>Know more</span>
              <h2>About Us</h2>
              <p>
                KEY CMA was founded by Valerie and Mike Bentien. Valerie, an
                award winning broker has been in the real estate business for
                over 20 years and Mike comes from a corporate background for
                many years. KEY CMA started with Valerie and an idea to do
                things a little differently. After many transactions over the
                years she felt there was a better way of presenting a CMA. All
                MLS systems provide incredible information but it is not being
                used to its fullest potential. KEY CMA is built on the
                fundamental principles a professional appraiser would use. The
                specifics of providing actual monetary values to the unique
                features of a property is a game changer. After many
                conversations with professionals in the industry we felt a
                passion to develop a program that will benefit their business.
              </p>
            </div>
          </div>
          <div className="aboutus-inner-second">
            <div className="container">
              <div className="aboutus-inner">
                <span>Why we are</span>
                <h2>Our Purpose</h2>
                <p>
                  Being ethical and good stewards of the real estate industry.
                </p>
                <p>
                  Providing value and up to date information for real estate
                  agents to be more competitive.
                </p>
                <p> An advocate for the success for the real estate agent.</p>
                <p>Have a little fun along the way!</p>
                <div className="about-signin">
                  <button
                    className="btn btn-pink btn-outline-success"
                    onClick={() => {
                      navigate("/login");
                      dispatch(loginState("login"));
                    }}
                  >
                    Sign In
                  </button>
                </div>
                <div className="AboutUsImageContainer">
                  <img src={Adetailsimg ? Adetailsimg : " "} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default AboutUs;
