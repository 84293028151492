import { defaultStateValue } from "../../utils/helper";

const initialState = {
  base64Images: [],
  allCsvData: [],
  clientName: "",
  reportName:"COMPARATIVE MARKET ANALYSIS",
  csvNewData: {
    added_COMPARABLESLIST: {
      Current_State: "",
      Sold_Date: "",
      ofchange: "",
      Sold_Price: "",
    },
    Levels: "",
    "Year Built": "",
    "Lot Size": "",
    "Above Grade Square Feet": "",
    "Basement Square Feet": "",
    "Finished Square Feet": "",
    "Total Square Feet": "",
    "Heating/Cooling": "",
    Fireplace: "",
    Bedrooms: "",
    Bathrooms: "",
    Garage: "",
    "Garage Spaces": "",
    "Garage Type": "",
    "Public-Remarks": "",
    properties_address: "",
    add_adjustment: true,
    notes: "",
    uuid: "",

    propertyImages: [],
    selectedAdditionalInfo: [],
    checked: [],
    additionalHomeFacts: {},
  },
  options: [
    { value: "One", label: "One" },
    { value: "Two", label: "Two" },
    { value: "Three Or More", label: "Three Or More" },
    { value: "Bi-Level", label: "Bi-Level" },
    { value: "Tri-Level", label: "Tri-Level" },
    { value: "Multi/Split", label: "Multi/Split" },
  ],
  formula_Division_DefaultValue: "",
  listing_id_arr: [],
  address: "",
  selectedPropertydataUUID: "",
  selectedPropertyAddress: "",
  comaparables: [],
  selectPropertyList: [],
  selectedAdditionalInfo: [],
  selectedPropertydata: [
    {
      Levels: "",
      "Year Built": "",
      "Lot Size": "",
      "Above Grade Square Feet": "",
      "Basement Square Feet": "",
      "Finished Square Feet": "",
      "Total Square Feet": "",
      "Heating/Cooling": "",
      Fireplace: "",
      Bedrooms: "",
      Bathrooms: "",
      Garage: "",
      "Garage Spaces": "",
      "Garage Type": "",
      properties_address: "",
      propertyImages: [],
      selectedAdditionalInfo: [],
      additionalHomeFacts: {},
    },
  ],
  range: { start: "", end: "" },
  adjustment_formulas: [],
  review_data: {},
  report_data: [],
  defaultFormulaState: defaultStateValue,
  changeDefaultValues: {},
  userAddedDefaultValues: "",
  count: 0,
  inputAddressState: false,
  businessImages: [],
  isUploadCSV: true,
  extracomparable: [],
  comarablePropertyuuid: "",
  color: "#0070c0",
  csvType: { value: "ReColorado", label: "ReColorado" },
  removedComparableList: [],
  newComparableUpload:[],
  newCSVComparableUpload:[]
};

const UserReduser = (state = initialState, action) => {
  switch (action.type) {
    case "csvNewData":
      return {
        ...state,
        csvNewData: action.payload,
      };
    case "changeDefaultValues":
      return {
        ...state,
        changeDefaultValues: action.payload,
      };
    case "userAddedDefaultValues":
      return {
        ...state,
        userAddedDefaultValues: action.payload,
      };
    case "count":
      return {
        ...state,
        count: action.payload,
      };
    case "reviewData":
      return {
        ...state,
        review_data: action.payload,
      };
    case "formula_Division_DefaultValue":
      return {
        ...state,
        formula_Division_DefaultValue: action.payload,
      };
    case "reportData":
      return {
        ...state,
        report_data: action.payload,
      };

    case "selectedPropertydata":
      return {
        ...state,
        selectedPropertydata: action.payload,
      };
    case "selectedPropertydataUUID":
      return {
        ...state,
        selectedPropertydataUUID: action.payload,
      };
    case "selectedPropertyAddress":
      return {
        ...state,
        selectedPropertyAddress: action.payload,
      };
    case "clientName":
      return {
        ...state,
        clientName: action.payload,
      };
    case "ReportName":
      return {
        ...state,
        reportName: action.payload,
      };
    case "Address":
      return {
        ...state,
        address: action.payload,
      };

    case "allCsvData":
      return {
        ...state,
        allCsvData: action.payload,
      };
    case "selectedAdditionalInfo":
      return {
        ...state,
        selectedAdditionalInfo: action.payload,
      };
    case "PropertyImages":
      return {
        ...state,
        propertyImages: action.payload,
      };
    case "uuid":
      return {
        ...state,
        uuid: action.payload,
      };
    case "comaparableData":
      return {
        ...state,
        comaparables: action.payload,
      };
    case "PageChecked":
      return {
        ...state,
        checked: action.payload,
      };
    case "selectPropertyList":
      return {
        ...state,
        selectPropertyList: action.payload,
      };
    case "propertyPriceRange":
      return {
        ...state,
        range: action.payload,
      };

    case "adjustmentFormulas":
      return {
        ...state,
        adjustment_formulas: action.payload,
      };
    case "ListArray":
      return {
        ...state,
        listing_id_arr: action.payload,
      };
    case "InputAddressState":
      return {
        ...state,
        inputAddressState: action.payload,
      };
    case "businessimages":
      return {
        ...state,
        businessImages: action.payload,
      };
    case "UploadCSVFlag":
      return {
        ...state,
        isUploadCSV: action.payload,
      };

    case "base64Images":
      return {
        ...state,
        base64Images: action.payload,
      };
    case "extracomparable":
      return {
        ...state,
        extracomparable: action.payload,
      };
    case "comarablePropertyuuid":
      return {
        ...state,
        comarablePropertyuuid: action.payload,
      };
    case "PDF_COLOR":
      return {
        ...state,
        color: action.payload,
      };
    case "OPTIONS":
      return {
        ...state,
        options: action.payload,
      };
    case "RESET_USER_REDUCER":
      return initialState;
    case "csvType":
      return {
        ...state,
        csvType: action.payload,
      };
    case "REMOVED_COMPARABLE_LIST":
      return {
        ...state,
        removedComparableList: action.payload,
      };
      // new comparable upload
    case "UPLOAD_COMPARABLE_LIST":
      return {
        ...state,
        newComparableUpload: action.payload,
      };

    case "CSV_UPLOAD_COMPARABLE_LIST":
      return {
        ...state,
        newCSVComparableUpload: action.payload,
      };
    default:
      return state;
  }
};

export default UserReduser;
