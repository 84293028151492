const initialState = {
  loginstate: "",
  subscriptionPlan: {},
  allSubcriptionPlan: {},
  businessAndSubscriptionDetails: {},
  profileImage: "",
  passData: "",
  promocode: {},
};
export const LoginReduser = (state = initialState, action) => {
  switch (action.type) {
    case "loginstate":
      return {
        ...state,
        loginstate: action.payload,
      };
    case "subcriptionPlan":
      return {
        ...state,
        subscriptionPlan: action.payload,
      };

    case "passData":
      return {
        ...state,
        passData: action.payload,
      };
    case "allSubcriptionPlan":
      return {
        ...state,
        allSubcriptionPlan: action.payload,
      };

    case "businessAndSubscriptionDetails":
      return {
        ...state,
        businessAndSubscriptionDetails: action.payload,
      };
    case "promocode":
      return {
        ...state,
        promocode: action.payload,
      };

    case "profileImage":
      return {
        ...state,
        profileImage: action.payload,
      };
    case "RESET_LOGIN_REDUCER":
      return initialState;

    default:
      return state;
  }
};
