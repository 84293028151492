import React from "react";
import LoaderImg from "../../Common/img/loader.svg";
import "./Loader.scss";
const Loader = (props) => {
  return (
    <div className="loader-main">
      <div
        className="loader-icon"
        style={{ height: props.height, width: props.height }}
      >
        <img src={LoaderImg} />
      </div>
    </div>
  );
};

export default Loader;
