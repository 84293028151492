import React from "react";
import Footer from "../../Components/footer/Footer";
import Header from "../header/Header";

const PrivayPolicy = () => {
  return (
    <>
      <div
        className="main-block"
        style={{
          textAlign: "left",
        }}
      >
        <div>
          <Header isLandingHeader />
        </div>
        <div className="">
          <div className="container">
            <div className="">
              <h2 style={{ color: "$blue-color" }}>PRIVACY POLICY</h2>
              <p>
                KeyCMA respects your privacy and is committed to protecting it
                through compliance with this Privacy Policy. This Policy
                describes how we handle your data when use the Site or services
                offered by KeyCMA. How we handle information about you depends
                on how, and the purposes for which, we receive your information.
              </p>
            </div>
          </div>
          <div className="">
            <div className="container">
              <div className="">
                <span style={{ fontWeight: "bold" }}>
                  Information We May Collect
                </span>

                <p>
                  <span>Information You Provide. </span> KeyCMA receives and may
                  store any information you enter on the Site or give to us in
                  any other way. Information you may provide include the
                  following:
                </p>
                <p>
                  1. When you sign up for an account, you may be required to
                  give us your name, email address, street address, and phone
                  number, and additional information that may enable you to
                  access portion of the Site or receive services.
                </p>
                <p>
                  2. We may collect your credit card numbers bank account
                  information when you sign up for or pay from Services. Such
                  information may be stored by a third-party payment processors.
                </p>
                <h5 style={{ color: "$blue-color" }}>
                  How We Use and Share Information
                </h5>
                <div>
                  We use, store, and process information, including personal
                  information, to provide and understand, improve, and develop
                  the Site and the Services. This information is used:
                </div>
                <p>1. To provide the Site and services.</p>
                <p>
                  2. To notify you of matters related to the Site or the
                  services, including expiration, terminations, and renewals.
                </p>
                <p>
                  3. To notify you about changes to the Site, services,
                  policies, or Terms of Use
                </p>
                <p>
                  4. KeyCMA may also gather information about the type of
                  browser you are using, IP address or type of operating system
                  to assist us in providing and maintaining superior quality
                  service.
                </p>
                <p>
                  Participating in the use of the Site, you are hereby accepting
                  the Terms of Use defined by the Privacy Policy Agreement. If
                  you are not agreement with our Terms of Use, you should
                  discontinue use of the Site. Continued use of the Site
                  following any updates or modifications to the Terms of Use
                  shall mean that you are in agreement and acceptance of such
                  changes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivayPolicy;
