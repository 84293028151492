export const loginState = (data) => {
  return {
    type: "loginstate",
    payload: data,
  };
};

export const subcriptionPlan = (data) => {
  return {
    type: "subcriptionPlan",
    payload: data,
  };
};

export const allSubcriptionPlan = (data) => {
  return {
    type: "allSubcriptionPlan",
    payload: data,
  };
};

// promocode save
export const promocodeadd = (data) => {
  return {
    type: "promocode",
    payload: data,
  };
};

export const businessAndSubscriptionDetails = (data) => {
  return {
    type: "businessAndSubscriptionDetails",
    payload: data,
  };
};

export const profileImage = (data) => {
  return {
    type: "profileImage",
    payload: data,
  };
};
export const clientName = (data) => {
  return {
    type: "clientName",
    payload: data,
  };
};

export const ReportName = (data) => {
  return {
    type: "ReportName",
    payload: data,
  };
};
//csv data
export const allCsvData = (data) => {
  return {
    type: "allCsvData",
    payload: data,
  };
};
export const changeDefaultValues = (data) => {
  return {
    type: "changeDefaultValues",
    payload: data,
  };
};
export const userAddedDefaultValues = (data) => {
  return {
    type: "userAddedDefaultValues",
    payload: data,
  };
};
export const csvNewData = (data) => {
  return {
    type: "csvNewData",
    payload: data,
  };
};
export const passAPIData = (data) => {
  return {
    type: "passData",
    payload: data,
  };
};

export const selectedPropertydataUUID = (data) => {
  return {
    type: "selectedPropertydataUUID",
    payload: data,
  };
};

export const selectedPropertyAddress = (data) => {
  return {
    type: "selectedPropertyAddress",
    payload: data,
  };
};
export const comaparableData = (data) => {
  return {
    type: "comaparableData",
    payload: data,
  };
};
export const selectedAdditionalInfo = (data) => {
  return {
    type: "selectedAdditionalInfo",
    payload: data,
  };
};

export const addressTitle = (data) => {
  return {
    type: "Address",
    payload: data,
  };
};
export const ListArray = (data) => {
  return {
    type: "ListArray",
    payload: data,
  };
};
export const InputAddressState = (data) => {
  return {
    type: "InputAddressState",
    payload: data,
  };
};
export const CountValue = (data) => {
  return {
    type: "count",
    payload: data,
  };
};
export const propertyImages = (data) => {
  return {
    type: "PropertyImages",
    payload: data,
  };
};
// new action
export const businessImage = (data) => {
  return {
    type: "businessimages",
    payload: data,
  };
};

export const setUuid = (data) => {
  return {
    type: "uuid",
    payload: data,
  };
};
export const pageChecked = (data) => {
  return {
    type: "PageChecked",
    payload: data,
  };
};
export const selectPropertyList = (data) => {
  return {
    type: "selectPropertyList",
    payload: data,
  };
};

export const selectedPropertydata = (data) => {
  return {
    type: "selectedPropertydata",
    payload: data,
  };
};
export const propertyPriceRange = (data) => {
  return {
    type: "propertyPriceRange",
    payload: data,
  };
};
export const adjustmentFormulas = (data) => {
  return {
    type: "adjustmentFormulas",
    payload: data,
  };
};
export const review_data = (data) => {
  return {
    type: "reviewData",
    payload: data,
  };
};
export const report_data = (data) => {
  return {
    type: "reportData",
    payload: data,
  };
};
export const formula_Division_DefaultValue = (data) => {
  return {
    type: "formula_Division_DefaultValue",
    payload: data,
  };
};

export const UploadCSVFlag = (data) => {
  return {
    type: "UploadCSVFlag",
    payload: data,
  };
};

// new
export const base64ImagesData = (data) => {
  return {
    type: "base64Images",
    payload: data,
  };
};

// -extra comparable
export const extracomparable = (data) => {
  return {
    type: "extracomparable",
    payload: data,
  };
};

export const comarablePropertyuuid = (data) => {
  return {
    type: "comarablePropertyuuid",
    payload: data,
  };
};

export const actionPdfColor = (data) => {
  return {
    type: "PDF_COLOR",
    payload: data,
  };
};

export const actioncsvType = (data) => {
  return {
    type: "csvType",
    payload: data,
  };
};

export const resetLoginReducer = () => ({
  type: "RESET_LOGIN_REDUCER",
});

export const resetUserReducer = () => ({
  type: "RESET_USER_REDUCER",
});

export const removedComparableList = (data) => ({
  type: "REMOVED_COMPARABLE_LIST",
  payload: data,
});


// new action comparable file upload
export const comparableListupload = (data)=>({
  type: "UPLOAD_COMPARABLE_LIST",
  payload: data,
})

export const csvComparableNewData = (data)=>({
  type: "CSV_UPLOAD_COMPARABLE_LIST",
  payload: data,
})