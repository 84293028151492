import React, { Fragment, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Loader from "./Components/Loader/Loader";
import AboutUs from "./Pages/aboutUs/AboutUs";
import PaymentCard from "./Components/Payment/PaymentCard/PaymentCard";
import PrivayPolicy from "./Pages/privacyPolicy/PrivayPolicy";
import TermsCondition from "./Pages/termcondition/TermsCondition";
import Contacts from "./Pages/Contact/Contacts";
const AddonComparables = React.lazy(() =>
  import("./Pages/AddOnComparables/AddonComparables")
);
const SubscriptionPlan = React.lazy(() =>
  import("./Pages/SubscriptionPlan/SubscriptionPlan")
);
const Forgot = React.lazy(() => import("./Pages/forgotPassword/Forgot"));
const NotFound = React.lazy(() => import("./Pages/NotFound/NotFound"));
const Home = React.lazy(() => import("./Pages/home/Home"));
const Login = React.lazy(() => import("./Pages/login/Login"));
const RequestDemo = React.lazy(() => import("./Pages/RequestDemo/requestdemo"));
const ConfirmSubject = React.lazy(() =>
  import("./Pages/confirmSubject/ConfirmSubject")
);
const ComparablesPage = React.lazy(() =>
  import("./Pages/comparables/ComparablesPage")
);
const Review = React.lazy(() => import("./Pages/review/Review"));
const Adjustment = React.lazy(() => import("./Pages/adjustment/Adjustment"));
const LandingPage = React.lazy(() => import("./Pages/landingPage/LandingPage"));

const EditProfile = React.lazy(() => import("./Pages/editprofile/EditProfile"));
const Report = React.lazy(() => import("./Pages/report/Report"));
const FAQComponent = React.lazy(() =>
  import("./Components/FAQComponent/FAQComponent")
);
const ChangeDefaultValues = React.lazy(() =>
  import("./Components/ChangeDefaultValues/ChangeDefaultValues")
);

// Check used loggedin or not
const Private = ({ Component }) => {
  const authorizeToken = localStorage.getItem("token");
  const subscribedToken = localStorage.getItem("subscribed");
  const auth =
    authorizeToken &&
    authorizeToken !== "" &&
    subscribedToken &&
    Number(subscribedToken) === 1
      ? true
      : false;
  return auth ? (
    <Suspense fallback={<Loader width={"120px"} height={"120px"} />}>
      <Component />
    </Suspense>
  ) : (
    <Navigate to="/" />
  );
};
const Guest = ({ Component }) => {
  const authorizeToken = localStorage.getItem("token");
  const subscribedToken = localStorage.getItem("subscribed");
  const auth =
    authorizeToken &&
    authorizeToken !== "" &&
    subscribedToken &&
    Number(subscribedToken) === 1
      ? true
      : false;
  return !auth ? (
    <Suspense fallback={<Loader width={"120px"} height={"120px"} />}>
      <Component />
    </Suspense>
  ) : (
    <Navigate to="/home" />
  );
};
const Routing = () => {
  return (
    <Fragment>
      <Router>
        <Routes>
          <Route path="/" element={<Guest Component={LandingPage} />} />
          <Route path="/card-details" element={<PaymentCard />} />
          <Route path="/login" element={<Guest Component={Login} />} />
          <Route path="/login?scan=true" element={<Guest Component={Login} />} />
          <Route
            path="/forgot-password"
            element={<Guest Component={Forgot} />}
          />
          <Route
            path="/reset-password/:token"
            element={<Guest Component={Forgot} />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivayPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/contact-us" element={<Contacts />} />
          <Route path="/home" element={<Private Component={Home} />} />
          <Route
            path="/change-default-values"
            element={<Private Component={ChangeDefaultValues} />}
          />
          <Route
            path="/adjustment"
            element={<Private Component={Adjustment} />}
          />
          <Route path="/faqs" element={<Private Component={FAQComponent} />} />
          <Route path="/review" element={<Private Component={Review} />} />
          <Route path="/report" element={<Private Component={Report} />} />
          <Route
            path="/edit-profile"
            element={<Private Component={EditProfile} />}
          />
          <Route
            path="/confirm-subject"
            element={<Private Component={ConfirmSubject} />}
          />
          <Route
            path="/compare"
            element={<Private Component={ComparablesPage} />}
          />
          <Route
            path="/addoncomparable"
            element={<Private Component={AddonComparables} />}
          />
          <Route
            path="/subscription-plan"
            element={<Guest Component={SubscriptionPlan} />}
          />
          <Route
            path="/request-demo"
            element={<Guest Component={RequestDemo} />}
          />
          <Route path="*" element={<Guest Component={NotFound} />} />
        </Routes>
      </Router>
    </Fragment>
  );
};

export default Routing;
