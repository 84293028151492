import moment from "moment";

export const priceformater = (value) => {
  return `$ ${Math.round(value).toLocaleString("en-US")}`;
};

export const UsFormate = (value) => {
  return value.toLocaleString("en-US");
};

function ExcelDateToJSDate(date) {
  const datenew = new Date(Math.round((date - 25569) * 86400 * 1000));
  return moment(datenew).format("MM/DD/YYYY");
}
export const getUUID = (e) => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
const GiveSoldData = (date) => {
  if (date !== "") {
    if (typeof date === "number") {
      let data = ExcelDateToJSDate(date);
      return data;
    } else {
      return date;
    }
  } else {
    return "Not Available";
  }
};
export const FunctionFilterCsvData = (allCsvData, type) => {
  let filterCsvData = [];
  let imageIds = [];
  allCsvData?.forEach((item) => {
    if (item["Listing Id"]) {
      imageIds.push(item["Listing Id"]);
    }
    let data;
    if (type === "ReColorado") {
      data = {
        Levels: item["Levels"],
        "Year Built": item["Year Built"],
        "Lot Size": item["Lot Size Square Feet"]
          ? Math.round(item["Lot Size Square Feet"])
          : 0,
        "Above Grade Finished Area": item["Above Grade Finished Area"]
          ? item["Above Grade Finished Area"]
          : 0,
        "Below Grade Total Area":
          (item["Below Grade Unfinished Area"]
            ? item["Below Grade Unfinished Area"]
            : 0) +
          (item["Below Grade Finished Area"]
            ? item["Below Grade Finished Area"]
            : 0),
        "Below Grade Finished Area": item["Below Grade Finished Area"]
          ? item["Below Grade Finished Area"]
          : 0,
        "Total Square Feet": item["Building Area Total"]
          ? item["Building Area Total"]
          : 0,
        Fireplace: item["Fireplaces Total"] ? item["Fireplaces Total"] : 0,
        "Above Grade Bedrooms":
          item["Upper Level Bedrooms"] +
          item["Main Level Bedrooms"] +
          item["Lower Level Bedrooms"],
        "¾ and Full Bathrooms":
          (item["Bathrooms Full"] || 0) +
          (item["Bathrooms Three Quarter"] || 0),
        "¼ and ½ Bathrooms":
          (item["Bathrooms Half"] || 0) + (item["Bathrooms One Quarter"] || 0),
        "Garage Spaces": item["Garage Spaces"] ? item["Garage Spaces"] : 0,
        properties_address: item["Unit Number"]
          ? `${item["Street Number"]}  ${item["Street Name"]} ${
              item["City"]
            } ${item["State Or Province"]?.slice(0, 2).toUpperCase()} ${
              item["Postal Code"]
            } (unit ${item["Unit Number"]})`
          : `${item["Street Number"]}  ${item["Street Name"]} ${
              item["City"]
            } ${item["State Or Province"]?.slice(0, 2).toUpperCase()} ${
              item["Postal Code"]
            }`,
        pdf_address: `${item["Street Number"]}  ${item["Street Name"]}`,

        add_adjustment: true,
        "Public-Remarks": item["Public Remarks"]
          ? item["Public Remarks"]
          : "Not Available",
        "Net Sales Price":
          (item["Close Price"] ? Number(item["Close Price"]) : 0) -
          (item["Concessions Amount"] ? Number(item["Concessions Amount"]) : 0),
        notes: "",
        uuid: getUUID(),
        propertyImages: [],
        selectedAdditionalInfo: [],
        subjectAdditionalInfo: [],
        checked: "",
        agentNotes: "",
        "Listing Id": item["Listing Id"],
        Latitude: item.Latitude,
        Longitude: item.Longitude,
        "Day on market": item["Days In MLS"],
        Exclusions: item.Exclusions,
        Roof: item.Roof,
        "kitchen appliences": item["Appliances"],
        "Prop Type": item["Property Sub Type"],
        County: item["County Or Parish"],
        "Full baths": item["Bathrooms Full"],
        "3/4 baths": item["Bathrooms Three Quarter"],
        "Half baths": item["Bathrooms Half"],
        "List Price": item["List Price"],
        High: item["High School"],
        "Org List Price": item["Original List Price"],
        Elementary: item["Elementary School"],
        "Assoc Fee": item["Association Fee"],
        perSquareFeet: item["PSF Above Grade"] ? item["PSF Above Grade"] : 0,
        "Association Fee Includes": item["Association Fee Includes"],
        "Association Name Primary": item["Association Name"],
        "Basement Type": item["Basement"],
        Concession: item["Concessions Amount"],
        "Construction Materials": item["Construction Materials"],
        cooling: item["cooling"],
        "Exterior Features": item["Exterior Features"],
        "Faces Direction": item["Direction Faces"],
        "Fireplace Features": item["Fireplace Features"],
        Flooring: item["Flooring"],
        Heating: item["Heating"],
        "Interior Features": item["Interior Features"],
        "Is Incorporated YN": item["Is Incorporated YN"],
        "Laundry Features": item["Laundry Features"],
        "Parking Features": item["Parking Features"],
        Restrictions: item["Restrictions Covenants"],
        "Road Surface": item["Road Surface Type"],
        Sewer: item["Sewer"],
        Utilities: item["Utilities"],
        View: item["View"],
        Zoning: item["Zoning"],
        "School District": item["Elementary School District"],
        MLS: item["Listing Id"] ? item["Listing Id"] : "Not Available",
        Neighborhood: item["Subdivision Name"],
        "Structure Style": item["Structure tupe"],
        Taxes: item["Tax Annual Amount"],
        "Unit Number": item["Unit Number"],
        Bedrooms:
          item["Upper Level Bedrooms"] +
          item["Main Level Bedrooms"] +
          item["Lower Level Bedrooms"],
        Bathrooms:
          item["Upper Level Bathrooms"] +
          item["Main Level Bathrooms"] +
          item["Lower Level Bathrooms"],
        added_COMPARABLESLIST: {
          Current_State: item["Mls Status"]
            ? item["Mls Status"]
            : "Not Available",
          Sold_Date: GiveSoldData(item["Close Date"]),
          Sold_Price: item["Close Price"]
            ? item["Close Price"]
            : "Not Available",
        },
        additionalHomeFacts: {
          Location: {
            Undesirable: false,
            Noisy: false,
            Average: false,
            Good: false,
            Amazing: false,
          },
          "Basement Type": {
            None: false,
            "In-Ground": false,
            "Garden Level": false,
            Walkout: false,
          },
          Windows: {
            "Below Average": false,
            Average: false,
            "Above Average": false,
            "Excellent New": false,
          },
          "Kitchen Condition": {
            Dated: false,
            "Typical Average": false,
            "Partial Update Low": false,
            "Partial Update High": false,
            "Full Update": false,
          },
          "Bathroom Condition": {
            Dated: false,
            "Typical Average": false,
            "Partial Update Low": false,
            "Partial Update High": false,
            "Full Update": false,
          },
          "Heating/Cooling": {
            None: false,
            Evaporative: false,
            "Central Air": false,
            "Split Zone": false,
          },
        },
        adjustment_formulas: [],
        review_data: {},
      };
    } else if (type === "PPAR") {
      data = {
        Levels: item["Levels"],
        "Year Built": item["YearBuilt"],
        "Lot Size": item["LotSizeArea"] ? Math.round(item["LotSizeArea"]) : 0,
        "Above Grade Finished Area": item["SqftAbove"] ? item["SqftAbove"] : 0,
        "Below Grade Total Area":
          (item["Below Grade Unfinished Area"]
            ? item["Below Grade Unfinished Area"]
            : 0) + (item["SqftFinished"] ? item["SqftFinished"] : 0),
        "Below Grade Finished Area": item["SqftFinished"]
          ? item["SqftFinished"]
          : 0,
        "Total Square Feet": item["SqftTotal"] ? item["SqftTotal"] : 0,
        Fireplace: item["Fireplaces Total"] ? item["Fireplaces Total"] : 0,
        "Above Grade Bedrooms": item["BedsTotal"],
        "Above Grade Bathrooms": 0,
        "Garage Spaces": item["GarageSpaces"] ? item["GarageSpaces"] : 0,
        properties_address: item["Unit Number"]
          ? `${item["StreetNumber"]}  ${item["StreetName"]} ${
              item["City"]
            } ${item["StateOrProvince"]?.slice(0, 2).toUpperCase()} ${
              item["PostalCode"]
            } (unit ${item["Unit Number"]})`
          : `${item["StreetNumber"]}  ${item["StreetName"]} ${
              item["City"]
            } ${item["StateOrProvince"]?.slice(0, 2).toUpperCase()} ${
              item["PostalCode"]
            }`,
        pdf_address: `${item["StreetNumber"]}  ${item["StreetName"]}`,

        add_adjustment: true,
        "Public-Remarks": item["Public Remarks"]
          ? item["Public Remarks"]
          : "Not Available",
        "Net Sales Price":
          (item["ClosePrice"] ? Number(item["ClosePrice"]) : 0) -
          (item["SoldConcessionRemarks"]
            ? Number(item["SoldConcessionRemarks"])
            : 0),
        notes: "",
        uuid: getUUID(),
        propertyImages: [],
        selectedAdditionalInfo: [],
        subjectAdditionalInfo: [],
        checked: "",
        agentNotes: "",
        "Listing Id": item["MLSNumber"],
        Latitude: item.Latitude,
        Longitude: item.Longitude,
        "Day on market": item["Days In MLS"],
        Exclusions: item.Exclusions,
        Roof: item.Roof,
        "kitchen appliences": item["Appliances"],
        "Prop Type": item["Property Sub Type"],
        County: item["County Or Parish"],
        "Full baths": item["Bathrooms Full"],
        "3/4 baths": item["Bathrooms Three Quarter"],
        "Half baths": item["Bathrooms Half"],
        "List Price": item["List Price"],
        High: item["High School"],
        "Org List Price": item["Original List Price"],
        Elementary: item["Elementary School"],
        "Assoc Fee": item["Association Fee"],
        perSquareFeet: item["SPSqft"] ? item["SPSqft"] : 0,
        "Association Fee Includes": item["Association Fee Includes"],
        "Association Name Primary": item["Association Name"],
        "Basement Type": item["Basement"],
        Concession: item["SoldConcessionRemarks"],
        "Construction Materials": item["Construction Materials"],
        cooling: item["cooling"],
        "Exterior Features": item["Exterior Features"],
        "Faces Direction": item["Direction Faces"],
        FirePlaceDesc: item["FirePlaceDesc"],
        Flooring: item["Flooring"],
        Heating: item["Heating"],
        "Interior Features": item["Interior Features"],
        "Is Incorporated YN": item["Is Incorporated YN"],
        "Laundry Features": item["Laundry Features"],
        "Parking Features": item["Parking Features"],
        Restrictions: item["Restrictions Covenants"],
        "Road Surface": item["Road Surface Type"],
        Sewer: item["Sewer"],
        Utilities: item["Utilities"],
        View: item["View"],
        Zoning: item["Zoning"],
        "School District": item["Elementary School District"],
        MLS: item["MLSNumber"] ? item["MLSNumber"] : "Not Available",
        Neighborhood: item["Subdivision Name"],
        "Structure Style": item["Structure tupe"],
        Taxes: item["Tax Annual Amount"],
        "Unit Number": item["Unit Number"],
        Bedrooms: item["BedsTotal"],
        Bathrooms: 0,
        added_COMPARABLESLIST: {
          Current_State: item["Status"] ? item["Status"] : "Not Available",
          Sold_Date: GiveSoldData(item["CloseDate"]),
          Sold_Price: item["ClosePrice"] ? item["ClosePrice"] : "Not Available",
        },
        additionalHomeFacts: {
          Location: {
            Undesirable: false,
            Noisy: false,
            Average: false,
            Good: false,
            Amazing: false,
          },
          "Basement Type": {
            None: false,
            "In-Ground": false,
            "Garden Level": false,
            Walkout: false,
          },
          Windows: {
            "Below Average": false,
            Average: false,
            "Above Average": false,
            "Excellent New": false,
          },
          "Kitchen Condition": {
            Dated: false,
            "Typical Average": false,
            "Partial Update Low": false,
            "Partial Update High": false,
            "Full Update": false,
          },
          "Bathroom Condition": {
            Dated: false,
            "Typical Average": false,
            "Partial Update Low": false,
            "Partial Update High": false,
            "Full Update": false,
          },
          "Heating/Cooling": {
            None: false,
            Evaporative: false,
            "Central Air": false,
            "Split Zone": false,
          },
        },
        adjustment_formulas: [],
        review_data: {},
      };
    }
    filterCsvData.push(data);
  });
  return { filterCsvData: filterCsvData, imageIds: imageIds };
};

export const changeFormateOfApiData = (dataAPI, updateState) => {
  const dataMap = {
    "Kitchen Conditon": "Kitchen Condition",
    "Bathrooms Conditon": "Bathrooms Condition",
    "Cooling System": "Heating/Cooling",
  };
  let finalArray = [];
  dataAPI?.forEach((ditem) => {
    updateState?.forEach((item) => {
      if (String(Object.keys(item)) === ditem.style_parent_label) {
        finalArray.push({ [Object.keys(item)]: extractData(ditem) });
      }
    });

    if (dataMap[ditem.style_parent_label]) {
      finalArray.push({
        [dataMap[ditem.style_parent_label]]: extractData(ditem),
      });
    }
  });

  let temp = {};
  if (finalArray.length > 0) {
    temp = convertArrayIntoObject(finalArray, temp);
    return temp;
  }
  return temp;
};
const convertArrayIntoObject = (array, object) => {
  for (let i = 0; i < array.length; i++) {
    Object.assign(object, array[i]);
  }
  return object;
};
function extractData(ditem) {
  return {
    FirstRange: ditem.amt_range_one,
    SecoundRange: ditem.amt_range_two,
    ThirdRange: ditem.amt_range_three,
    style_slug: ditem.style_slug,
  };
}
export const defaultStateValue = {
  FirstRange: "$0-$500,000",
  SecoundRange: "$501,000-$1 Million",
  ThirdRange: "1 Million and over",
  "Year Built": {
    FirstRange: 500,
    SecoundRange: 800,
    ThirdRange: 1000,
  },
  "Lot Size": {
    FirstRange: 1,
    SecoundRange: 1,
    ThirdRange: 1,
  },
  Fireplace: {
    FirstRange: 2000,
    SecoundRange: 5000,
    ThirdRange: 7000,
  },
  Garage: {
    FirstRange: 5000,
    SecoundRange: 8000,
    ThirdRange: 15000,
  },
  Bedrooms: {
    FirstRange: 5000,
    SecoundRange: 10000,
    ThirdRange: 15000,
  },
  "3/4 and Full Bathrooms": {
    FirstRange: 5000,
    SecoundRange: 10000,
    ThirdRange: 15000,
  },
  "1/4 and 1/2 Bathrooms": {
    FirstRange: 5000,
    SecoundRange: 10000,
    ThirdRange: 15000,
  },

  "Basement Type": {
    FirstRange: 10000,
    SecoundRange: 20000,
    ThirdRange: 30000,
  },
  "Heating/Cooling": {
    FirstRange: 3000,
    SecoundRange: 5000,
    ThirdRange: 10000,
  },
  "Kitchen Condition": {
    FirstRange: 4000,
    SecoundRange: 7000,
    ThirdRange: 15000,
  },
  "Bathroom Condition": {
    FirstRange: 5000,
    SecoundRange: 15000,
    ThirdRange: 20000,
  },
  Location: {
    FirstRange: 5000,
    SecoundRange: 15000,
    ThirdRange: 20000,
  },
  Windows: {
    FirstRange: 20000,
    SecoundRange: 30000,
    ThirdRange: 40000,
  },
  "Above grade finished area": {
    FirstRange: 3,
    SecoundRange: 3,
    ThirdRange: 3,
  },
  "Below grade total area": {
    FirstRange: 16.2,
    SecoundRange: 16.2,
    ThirdRange: 16.2,
  },
  "Below grade finished area": {
    FirstRange: 9.25,
    SecoundRange: 9.25,
    ThirdRange: 9.25,
  },
};
export const classifyPercentageColor = (percentage) => {
  if (percentage >= 0 && percentage <= 10) {
    return {
      background:
        "linear-gradient(to right, rgba(27, 224, 67, 0.3) 0%, white 100%)",
    };
  } else if (percentage > 10 && percentage <= 20) {
    return {
      background:
        "linear-gradient(to right, rgb(248 241 0 / 30%) 0%, white 100%)",
    };
  } else {
    return {
      background:
        "linear-gradient(to right, rgba(248, 49, 49, 0.3) 0%, white 100%)",
    };
  }
};
export const classifyPercentageColorForSpan = (percentage) => {
  if (percentage >= 0 && percentage <= 10) {
    return {
      color: "#1dd63d",
    };
  } else if (percentage > 10 && percentage <= 20) {
    return {
      color: "#FFBF00",
    };
  } else {
    return {
      color: "#ef0808",
    };
  }
};
//US formate
export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const regex = /^(\d{3})(\d{3})(\d{4})$/;
  const formatted = cleaned.replace(regex, "$1-$2-$3");
  return formatted;
};
