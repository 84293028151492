import "./App.scss";
import Routing from "./routing";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch } from "react-redux";

let stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
function App() {
  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <Routing />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          icon={false}
        />
      </div>
    </Elements>
  );
}

export default App;
